export const INDUSTRIES = [
  "Technology",
  "Food",
  "Travel",
  "Education",
  "Health/Wellness",
  "Fashion",
  "Beauty",
  "Sports",
  "Gaming",
  "Pets",
  "Entertainment",
];

export const POPULAR_BRAND_CHANNEL = [{
platform:"Instagram",
icon:"instagram.png",
audience:"2B+",
engagementRate:"4.9%",
popularFormate:["Short Form Video", "Static Image Posts"],
growthRate:"5.7%"
},{
  platform:"TikTok",
  audience:"1.5B+",
  engagementRate:"3.2%",
  popularFormate:["Short Form Video"],
  growthRate:"6.7%",
  icon:"tiktok-big.png"
  },{
    platform:"YouTube",
    audience:" 2.5B+",
    engagementRate:"2%",
    popularFormate:["Short and Long Form Video"],
    growthRate:" 4.8%",
    icon:"youtube-big.png"
    },{
      platform:"LinkedIn",
      audience:"800M+",
      engagementRate:"2.5%",
      popularFormate:["Text-Based", "Static Image Posts"],
      growthRate:"3.2%",
      icon:"linkedin-big.png"
      },{
        platform:"Twitter",
        audience:"600M+",
        engagementRate:"2.2%",
        popularFormate:["Text-Based Posts"],
        growthRate:"6%",
        icon:"twitter-big.png"
        }];

export const BRAND_CONTENT_FORMATE = [{
  title:"Shorts Form Video",
  platforms:["instagram", "tiktok", "youtube", "facebook"],
  engagementRate:"High",
  icon:"trending/video-replay.png",
},
{
  title:"Carousel Image Posts",
  platforms:["instagram", "linkedin", "facebook"],
  engagementRate:"Medium",
  icon:"trending/gallery.png",
},
{
  title:"Single Image Posts",
  platforms:["instagram","facebook","linkedin","twitter"],
  engagementRate:"High",
  icon:"trending/gallery.png",
},
{
  title:"Long Form Video",
  platforms:["youtube"],
  engagementRate:"Medium",
  icon:"trending/video-replay.png",
},
{
  title:"Text Based Content",
  platforms:["linkedin","twitter"],
  engagementRate:"Medium",
  icon:"trending/text.png"
},
]

export const TRENDING_AUDIO = [{
  title: "Big Dawgs - Hunumankind",
  link: "https://www.instagram.com/reels/audio/475442598416077?igsh=MWRweTgyNTh1d2tlMg==",
  posts: "2.6M+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "Bye Bye Bye - NSYNC",
  link: "https://www.instagram.com/reels/audio/252168608699319/",
  posts: "800K+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "Juna - Clairo",
  link: "https://www.instagram.com/reels/audio/1662934874536027/",
  posts: "92K+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "Kickstart My Heart - Motley Creu",
  link: "https://www.instagram.com/reels/audio/1014523328754040?igsh=dWF6OTh2cDR0dGFm",
  posts: "115K+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "Apple - Charli_xcx",
  link: "https://www.instagram.com/reels/audio/268777446194642?igsh=MTY4dnE3bjB5enAzNQ==",
  posts: "300K+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "Guess - Charli_xcx",
  link: "https://www.instagram.com/reels/audio/1235451451148870?igsh=dWswbjB3OHNpMXBp",
  posts: "200K+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "Soh-Soh - Iamodeal",
  link: "https://www.instagram.com/reels/audio/461115589969942?igsh=MXJ3ZmQ0azAwNXB0Ng==",
  posts: "300K+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "Sparks - Coldplay",
  link: "https://www.instagram.com/reels/audio/592467431123776?igsh=aWlxMzJ0bndlcGN5",
  posts: "400K+",
  platforms: ["instagram","facebook", "youtube"]
}, {
  title: "You - Tash",
  link: "https://www.instagram.com/reels/audio/1727097854417516?igsh=cTU4OHMzbzluazV0",
  posts: "100K+",
  platforms: ["instagram","facebook", "youtube"]
},]

export const TRENDING_HASHTAG = [{
  hashtag: "#love",
  platforms: ["instagram", "tiktok", "twitter"],
  post: ""
},
{
  hashtag: "#instagood",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#fashion",
  platforms: ["instagram", "twitter"],
  post: ""
},
{
  hashtag: "#photooftheday",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#photography",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#art",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#beautiful",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#nature",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#picoftheday",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#happy",
  platforms: ["instagram"],
  post: ""
},
{
  hashtag: "#fyp",
  platforms: ["tiktok"],
  post: ""
},
{
  hashtag: "#tiktok",
  platforms: ["tiktok"],
  post: ""
},
{
  hashtag: "#foryoupage",
  platforms: ["tiktok"],
  post: ""
},
{
  hashtag: "#viralvideos",
  platforms: ["tiktok"],
  post: ""
},
{
  hashtag: "#foryou",
  platforms: ["tiktok"],
  post: ""
},
{
  hashtag: "#funnyvideos",
  platforms: ["tiktok"],
  post: ""
},
{
  hashtag: "#trending",
  platforms: ["tiktok"],
  post: ""
}, {
  hashtag: "#viral",
  platforms: ["tiktok"],
  post: ""
}, {
  hashtag: "#followme",
  platforms: ["tiktok"],
  post: ""
}, {
  hashtag: "#comedy",
  platforms: ["youtube"],
  post: ""
}, {
  hashtag: "#shorts",
  platforms: ["youtube"],
  post: ""
}, {
  hashtag: "#shortsvideo",
  platforms: ["youtube"],
  post: ""
}, {
  hashtag: "#fitness",
  platforms: ["youtube"],
  post: ""
}, {
  hashtag: "#vlog",
  platforms: ["youtube"],
  post: ""
}, {
  hashtag: "#tech",
  platforms: ["youtube", "linkedin"],
  post: ""
}, {
  hashtag: "#gaming",
  platforms: ["youtube"],
  post: ""
}, {
  hashtag: "#food",
  platforms: ["youtube", "twitter"],
  post: ""
}, {
  hashtag: "#travel",
  platforms: ["youtube", "twitter"],
  post: ""
}, {
  hashtag: "#beauty",
  platforms: ["youtube"],
  post: ""
}, {
  hashtag: "#innovation",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#management",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#humanresources",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#digitalmarketing",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#technology",
  platforms: ["linkedin", "twitter"],
  post: ""
}, {
  hashtag: "#creativity",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#future",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#futurism",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#entreprenuership",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#careers",
  platforms: ["linkedin"],
  post: ""
}, {
  hashtag: "#news",
  platforms: ["twitter"],
  post: ""
}, {
  hashtag: "#sports",
  platforms: ["twitter"],
  post: ""
}, {
  hashtag: "#health",
  platforms: ["twitter"],
  post: ""
}, {
  hashtag: "#business",
  platforms: ["twitter"],
  post: ""
}, {
  hashtag: "#foodie",
  platforms: ["twitter"],
  post: ""
}, {
  hashtag: "#follow",
  platforms: ["twitter"],
  post: ""
}
]

export const SOCIAL_LISTING = [{
  title: "Sabrina ",
  description: "Content using Sabrina Carpenter's music is trending, and in the beauty industry, the Sabrina Carpenter beauty routine has spiked in viewership and engagement.",
  timeline: "July 2024",
  headerDesc: "Content using Sabrina Carpenter's music is trending, and in the...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "OOTD & Fit Check",
  description: "Content showcasing multiple outfits is a trending theme. Especially content with summer outfit looks, using trending sounds on video.",
  timeline: "July 2024",
  headerDesc: "Content showcasing multiple outfits is a trending theme. Espec...",
  primaryChannel: ["instagram","tiktok"]

},
{
  title: "Politics",
  description: "Politics is a trending topic of discussion across social channels. Discussion on the political landscape has exponentially grown in engagement and viewership.",
  timeline: "July 2024",
  headerDesc: "Politics is a trending topic of discussion across social chan...",
  primaryChannel: ["instagram","tiktok","linkedin","twitter"]

}, {
  title: "Summer travel",
  description: "Travel vlogs and summer destinations/activities is a trending topic. Content surrounding travel destinations, how-to's, story times, summer activities, and vlogs have high engagement this month.",
  timeline: "July 2024",
  headerDesc: "Travel vlogs and summer destinations/activities is a trend...",
  primaryChannel: ["instagram","tiktok","youtube"]
},
{
  title: "2024 Paris Olympics",
  description: "The Olympics is a top trending topic this month. Content featuring Olympics reviews, athletics, sports, and athlete/workout routines is a trending topic. Sub-topics that are trending around the Olympics are the comeback of Simone Biles and Celine Dion, with many praising both performances as legends in their respective fields. In Canada, Summer McIntosh’s performance is trending with her multiple gold medal wins at the Olympics. Discussion on the opening ceremony and inclusion of break dancing in the Olympics has sparked widespread discussion across social media with many weighing in their thoughts. Discussion on Paris as a travel destination is also trending, with Paris travel tips being a focus. Olympics-inspired content is also trending, with creators piggybacking on the Olympics theme to create content - for example, “If Olympics had a medal for [X] I would win”",
  timeline: "August 2024",
  headerDesc: "The Olympics is a top trending topic this month. Content...",
  primaryChannel: ["instagram","tiktok","youtube","linkedin"]
},
{
  title: "I’m Just a Girl",
  description: "There is a booming trend of creators and businesses sharing videos and carousel posts with the theme of “I’m just a girl”, particularly on Instagram and Tiktok. This content focuses on women's issues & trending topics. Both women and men are weighing in with their thoughts on this trend that features common characteristics of women, like wanting to shop & dress fashionably, always wanting a great picture, or taking a yoga class, and the audio/text featuring the “I’m just a girl” as the explanation. The content primarily focuses on inspirational or comedic takes. The trend I’m Just a Girl correlates to the larger topic of women’s issues, and many businesses have hoped onto this trend to showcase their products or brand messaging to help women. The trending audio Just a Girl by U Rock, is commonly used for this trend. This trend has also expanded into conversational lingo with many people now using the statement “I’m just a girl” to explain their daily choices.",
  timeline: "August 2024",
  headerDesc: "There is a booming trend of creators and businesses shar...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Paging Dr. Beat",
  description: "Content using the Paging Dr. Beat format has been going viral, with videos using this trending format gaining higher viewership and engagement. These videos focus on inspirational content, like outfit inspiration, recipe inspiration, business growth tips, and travel inspiration. From a business lens, we have seen companies showcasing their favorite products, or comedic/educational content around their business and team.",
  timeline: "August 2024",
  headerDesc: "Content using the Paging Dr. Beat format has been going...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Back to School",
  description: "Back to school is one of the hottest topics growing on social, with students and parents sharing their back-to-school essentials, outfits, meal prep, and more. Throughout August and September, the topics around back-to-school season will be a trending topic. Comedic posts surrounding back-to-school have been trending at this time, as well as teacher-focused content, and parent’s tips/views on back-to-school for their kids.",
  timeline: "August 2024",
  headerDesc: "Back to school is one of the hottest topics growing on ...",
  primaryChannel: ["instagram","tiktok", "youtube","linkedin"]
},
{
  title: "You and Me Always Forever",
  description: 'There is a booming trend of creators sharing video posts with this carousel format, primarily on Instagram and TikTok. The content features things that live in pairs and how they are always the best together. This trend features three frames in a carousel-style reel often centered around friendship, pairs/duo, and sibling content. The first carousel showcases one product/individual/animal with the on-screen text “You”, the second carousel showcases another product/individual/animal with the onscreen text “Me” and the final carousel features both doing something together with on-screen text "Always Forever". Businesses use this trend to feature their products as the perfect pair for individuals. You can commonly see this trend being shared among friends and siblings with a popular meme cat and meme hampster. This content has a high engagement rate, with individuals tagging their friends, or sharing the video to their community.',
  timeline: "August 2024",
  headerDesc: "There is a booming trend of creators sharing video pos...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Give Me 14 Of Them",
  description: "A video featuring an individual loving pancakes so much, that he wanted an extra 14 of them went viral. This video has sparked thousands of creators to use the trending audio to share comedic videos, or product endorsements for their business. Many creators have also integrated that viral video clip into their videos to share how amazing something is. This audio or video is currently trending and boosting viewership and engagement. From a brand lens, this audio can easily be used to share how amazing your product, brand, or messaging is.",
  timeline: "August 2024",
  headerDesc: "A video featuring an individual loving pancakes so mu...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Family Fun & Travel",
  description: "In August, Family and Nostalgia centered content is trending. This month family travel is a big topic with many heading out for vacation this month, especially around the long weekends across North America. Video content surrounding family travel ideas, family-focused comedy, family recipe favorites, family dynamics etc. is trending. From a business lens, family-focused social content is winning.",
  timeline: "August 2024",
  headerDesc: "In August, Family and Nostalgia centered content is...",
  primaryChannel: ["instagram","tiktok", "youtube"]
},
{
  title: "Politics",
  description: "Discussion on the political landscape has exponentially grown in engagement and viewership. In particular, the US political landscape is a current hot topic on social, with many creators weighing in their thoughts on the Trump vs. Harris campaign. The broader conversation on politics has also spread in Canada and sparked increased viewership on topics of inflation, housing issues, debates, etc.",
  timeline: "August 2024",
  headerDesc: "Discussion on the political landscape has exponent...",
  primaryChannel: ["instagram","tiktok","linkedin","youtube"]
},{
  title: "Demure & Mindful",
  description: 'Recently, Tiktoker Jooles Lebron went viral for their go-to-work beauty look; describing it to be very demure, and very mindful. According to the TikToker, being demure is about being mindful of others and your environment, and appropriate with your actions. This trend has since widely spread across social media with people sharing their thoughts on being demure and mindful: 1. There is an exponential growth in creators sharing their #demure fashion/beauty looks, 2. There are discussion based videos on what it means for people, particularly women to be demure and mindful, and 3. Businesses are weighing in on being very demure and mindful, for example, “we don’t schedule 4 pm meetings on Fridays, we’re #demure and #mindful”.',
  timeline: "September 2024",
  headerDesc: "Recently, Tiktoker Jooles Lebron went viral for...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Brat Green & Brat Girl",
  description: "The Brat Green trend had taken over social media this summer, and is again rising in popularity moving into September. The Brat trend has sparked a movement, inspiring girls and women to unapologetically be themselves, and not be afraid of others' judgment - Tying to the larger topic of women’s empowerment. This trend began with the launch of Charli XCX’s album “Brat” and took over social media with Brat Green being the most trending colour, and the concept of being “brat” as hip, trendy, and cool. The music album has inspired thousands of posts on social media through dances, memes, and beauty/fashion trends, especially among young adults and teens. The song Apple from this album, has thousands of dance posts from creators, celebrities, and Olympic teams posting the trending dance. Phrases like “It's giving brat energy”, “brat girl”, and “brat summer/season” are now commonly used terms on social media - often about women and the overall “brat girl” aesthetic.",
  timeline: "September 2024",
  headerDesc: "The Brat Green trend had taken over social media this...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Back to School",
  description: "Back to school is one of the hottest topics on social this month, with students and parents sharing their back-to-school essentials, outfits, meal prep, and more. With school season starting this week, topics around back-to-school are top on the trending list. Comedic posts surrounding back-to-school have been trending at this time, as well as teacher-focused content, student recipes, and study tips/hacks are trending. Broader topics on education and student parenting tips are also trending.",
  timeline: "September 2024",
  headerDesc: "Back to school is one of the hottest topics on social...",
  primaryChannel: ["instagram","tiktok", "youtube"]
},
{
  title: "Generational Gaps",
  description: "Numerous posts are trending on the major language and lifestyle gaps between Gen Alpha, GenZ/Millenial versus the older generations. There are now trending posts around the new language slang among GenZ, and particularly Gen Alpha, which are almost incomprehensible for most older generations. The majority of these posts are comedic in nature. Businesses have also tapped into this trend with posts surrounding GenZ versus Millenial and Older workers. There is also a more serious side to this trend with many content creators discussing the opportunities, and cost of living differentiators between the GenZ/Millenial generation, versus the older generations. There is also a growing discussion of the need for better workplace culture, with GenZ valuing mental health and work-life balance as a priority in their work environment.",
  timeline: "September 2024",
  headerDesc: "Numerous posts are trending on the major language and...",
  primaryChannel: ["instagram","tiktok","youtube","linkedin"]
},
{
  title: "Dopamine Menu",
  description: 'The new trend Dopamine Menu, has gone viral across social media, particularly on Instagram and TikTok. This trend focuses on people sharing what makes them the happiest (gives them increased dopamine). This trend has expanded to a point where many now use the term dopamine menu in common conversation. Ex: “What’s on your dopamine menu?”, “Walks on the beach are high on my dopamine menu.” The main focus of this trend is showcasing lists of things that make you happy. Often showcased in reel or carousel format. Brands have also tapped into this trend through influencer marketing, where creators show which products and brands they cant live without as part of their dopamine menu. See more <a href="https://www.tiktok.com/tag/dopaminemenu" traget="blank">example here</a>',
  timeline: "September 2024",
  headerDesc: "The new trend Dopamine Menu, has gone viral across...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "What’s In My Bag",
  description: '“What’s in my bag” is a trending topic primarily among women, showing what’s in their bags. The trend centers around your aesthetic and what you carry in your purse/bag - many creators are showcasing their aesthetic beauty products, and daily essentials. This trend also builds off the demure trend, and being demure and mindful about whats in your purse. The trend also showcases what type of girl/women they are based on what they carry. The content is primarily in reel or carousel format with the text overlay “what’s in my bag”',
  timeline: "September 2024",
  headerDesc: '“What’s in my bag” is a trending topic primarily...',
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Business Trend: My Gen Z Marketing Team",
  description: 'Building off the Generational gaps trend, businesses have been going viral with their GenZ marketing content. Hotels are claiming their rooms “slay”, real estate agents say their homes are “giving vibes” etc. These videos showcase how GenZ would market their brands with GenZ slang. This content has exponentially grown in viewership, engagement, and converted to increased account followership. This content aims to engage with the GenZ population on social media, primarily across Instagram and Tiktok. A great way to tap into this trend is to showcase how GenZ would market your company, brand, or narrative using a script filled with GenZ slang. Commonly these videos have a text overlay saying “my GenZ employee wrote our script” or “We asked our GenZ employee to create a promo video” etc. See an example of a <a href="https://www.tiktok.com/@fyfieldmanor/video/7409716301087722784?_r=1&_t=8pZvLM8haYj" target="blank">viral video here</a>',
  timeline: "September 2024",
  headerDesc: "Building off the Generational gaps trend, busine...",
  primaryChannel: ["instagram","tiktok","youtube"]
},
{
  title: "Politics",
  description: "Discussion on the political landscape has exponentially grown in engagement and viewership this month. In particular, the US political landscape is a current hot topic on social, with many creators weighing in their thoughts on the Trump vs. Harris campaign. The recent US presidential debate has sparked the creation of thousands of videos with people sharing their views, thoughts on policy changes, and many comedic posts around key political statements. The broader conversation on politics has also spread and sparked increased viewership on topics of inflation, affordability, and immigration.",
  timeline: "September 2024",
  headerDesc: "Discussion on the political landscape has expone...",
  primaryChannel: ["instagram","tiktok","linkedin"]
},
{
  title: "Animal Focus: Moo Deng and Pesto",
  description: 'Moo Deng and Pesto have token over the Internet by storm. The latest trending topic on social media is the IT girl, Moo Deng, a baby pygmy hippo from Khao Kheow Open Zoo in Thailand. Moo Deng has become famous for her cuteness and sassy personality, with videos gaining millions of views across TikTok, Instagram and YouTube. Thousands of videos have been made about MooDeng, like "what kind of Moo Deng personality are you?" beauty routines with the "Moo Deng Makeup" and "Moo Deng Blush" Trend, among many others. Another baby that has gained celebrity status on social is the IT Boy, Pesto the Penguin. The baby penguin from Australia has become famous on social media for being one of the largest and heaviest penguins. Many videos about Pesto feature sibling/parent/family comedy content, and many creators have posted Pesto inspired outfits, recipes and more.',
  timeline: "October 2024",
  headerDesc: "Moo Deng and Pesto have token over the Internet...",
  primaryChannel: ["instagram","tiktok", "youtube"]
},
{
  title: "Cozy Season",
  description: "The cozy season of social is upon us, and there are now over 100K videos made on TikTok alone with the hasthags #fallvibes and #cozyseason. All things from cozy home design, comfort food, and cozy fall outfits are trending. Peope are looking for inspiration as they enter the fall season on things to buy and ways prepare for the season. Brands are tapping into this by ushering from summer fun content to more fall and winter themes.",
  timeline: "October 2024",
  headerDesc: "The cozy season of social is upon us, and there...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Do You Know You Have 30 Minutes?!",
  description: 'With over 200K videos made, content made with <a href="https://www.tiktok.com/@seanmperry_/video/7402359279778401578" target="blank">this audio</a> is going viral across Instagram and TikTok. Content creators are using this audio to share educational and comedic content around any topic that is time sensitive. Use this audio to share key time sensitive action items with your audience. For example, limited time offers, early birds, or new product launches.',
  timeline: "October 2024",
  headerDesc: "With over 200K videos made, content made with...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "This and Yap",
  description: 'This and Yap, is a trending topic accorss Instagram and TikTok. Creators are sharing their favorite places to be/things to do, accompanied with "Yap" (which means to chat, talk or gossip). This trend has gone so viral that many GenZ now use this statement in common language conversation. For example being on a beach and saying "nothing better than this and yap". Brands have also tappend into this trend by showing some of their favorite products, brand experiences, events and "Yap". See an <a href="https://www.tiktok.com/@seanmperry_/video/7402359279778401578" target="blank"></a>',
  timeline: "October 2024",
  headerDesc: "This and Yap, is a trending topic accorss Insta...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Brand Feature: Nutter Butter ",
  description: 'The Nutter Butter Social Media Account has sparked widespread commentary, criticism, and applause on TikTok. Nutter Butter recently went from a small account to over 1M followers on TikTok, and their strange and slightly eery TikTok brand marketing videos have been gaining millions of views. Numerous content creaotrs have taken to social media to talk about the Nutter Butter account theorizing the strategy and reasoning behind the companies new media strategy. This conversation has also spread from Tiktok to other channels like Instagram and LinkedIn among the marketing and creator community. The conversation has now balooned into the broader topic of what it means to be brand safe, and social-first. Many marketers and creators are stating that the account is "creative Genius" and "GenZ" as it has sparked such a massive response and followership.',
  timeline: "October 2024",
  headerDesc: "The Nutter Butter Social Media Account has spar...",
  primaryChannel: ["linkedin","tiktok"]
},
{
  title: "Halloween",
  description: "October is here and halloween is already on the top trending list. Before the halloween trending topic becomes saturated, its great to launch any halloween related content at the beginning of October. Halloween costumes, recipes, Harry Potter Themes and spooky design/decor are all trending this week.",
  timeline: "October 2024",
  headerDesc: "October is here and halloween is already on the...",
  primaryChannel: ["instagram","tiktok","youtube"]
},
{
  title: "Mental Health Spotlight",
  description: "Mental health is a trending topic this month. World mental health day has sparked increased discussion and content around topics of mental health, combatting stress, and the importance of a positive community/mindset. World mental health day has also sparked thousands of comedic posts around mental health and how people have learned to live with their heavy stress on a daily basis. Many brands are sharing their positive view points on mental health, and the need for awareness. The next two weeks are great to shed light on the topic of mental health.",
  timeline: "October 2024",
  headerDesc: "Mental health is a trending topic this month...",
  primaryChannel: ["instagram","tiktok","youtube"]
},
{
  title: "In the Klerb, we all fam.",
  description: 'With over 70K videos in under a week, <a href="https://www.tiktok.com/music/original-sound-7421694865471327007" target="blank">this sound</a> is going viral on Tiktok. Use this audio to share a topic in which something you are sharing just isn’t being understood. Majority of these posts are comedic in nature, and from a brand perspective you can use this audio to share your product/brand benefits that many people may not know about.',
  timeline: "October 2024",
  headerDesc: "With over 70K videos in under a week, this sound...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Humans saw this and then created ...",
  description: 'Use <a href="https://www.tiktok.com/discover/humans-saw-this-and-decided-to-create" target="blank">this trend </a> to show an amazing natural lansdcape and then something people invested thats very techy, boring, or constricting. For example, showing a beautiful beach with the text onscreen saying "humans saw this and created Excel, C++, Math, Wage Gaps etc."',
  timeline: "October 2024",
  headerDesc: "Use this trend to show an amazing natural natural lansd...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Spotlight on Health",
  description: "Health awareness topics are trending this month as November is a key month for driving awareness of mens health, diabetes and lung cancer. Content surrounding education of these topics are trending as creators shed light and their thoughts on health/wellness.  Movember is especially a trending topic on social as many male creators are growing their mustache in support of mens health awareness. This month is a good time for brands to post educational and awareness content around key health issues.",
  timeline: "November 2024",
  headerDesc: "Health awareness topics are trending this month as November...",
  primaryChannel: ["instagram","tiktok", "youtube", "linkedin"]
},
{
  title: "Conversations on Politics & Policy",
  description: "Discussion on the political landscape has exponentially grown in engagement and viewership. In particular, the US political landscape is a current hot topic on social, with many creators weighing in their thoughts on the Trump vs. Harris campaign and final election results. Many creators are also weighing in on the policy changes that are projected to come into effect under the new government leaders. The broader conversation has increased viewership on topics of gender, women's rights, inflation and immigration.",
  timeline: "November 2024",
  headerDesc: "Discussion on the political landscape...",
  primaryChannel: ["instagram","tiktok", "linkedin", "youtube"]
},
{
  title: "That Sums Me Up",
  description: "This trend is most often used in carousel format, and its used to share something specific about you/or your brand, and the second carousel saying 'that basically sums me/us up'. This content is used to share more about your core values, and/or brand identity. The content can also be used to showcase comedic content by saying something funny or cliche about yourself, OR used to share how you are dealing with grief and how you overcame it.",
  timeline: "November 2024",
  headerDesc: "This trend is most often used in carousel format...",
  primaryChannel: ["instagram","tiktok"]
},,
{
  title: "Add a Tip",
  description: "Tipping culture has become a big topic of conversation, and this trend brings a comedic angle to tipping. With over 150K posts on this trend and growing, this trend has high elvels of viewrship, engagement and virality. To hop onto this trend and boost engagement, brands and creators are sharing absurd ways to collect tips. ",
  timeline: "November 2024",
  headerDesc: "Tipping culture has become a big topic...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Camera Flip Trend",
  description: "The camera flip trend has gone viral across Tiktok and Instagram. The trend is used to share a topic/opinion and then the camera accidently flips on you to share your POV. Here is an example of how Taylor Lautner recently jumped on this trend, and his video received over 170 Million views. Many business/brand teams have also jumped onto this trend to share funny office videos. In the last week this trend is also going viral across Instagram. You can hop on this trend by creating a video sharing an opinion/view and then flip the camera to show your team. This content is typically comedic in nature.",
  timeline: "December 2024",
  headerDesc: "The camera flip trend has gone viral across...",
  primaryChannel: ["instagram","tiktok", "youtube"]
},
{
  title: "I Have an Announcement",
  description: "With over 30K posts, this trend is going viral on Instagram. Say you have an announcement, and share exciting news with your audience. This content is expecially trending with brands as they can use this audio to share exciting news about their products, holiday deals, or just share an exciting new brand offering. You can hop onto this trend to share any new resources, or programs being launched.",
  timeline: "December 2024",
  headerDesc: "With over 30K posts, this trend is going viral...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "We listen and we dont judge",
  description: "This trend is blowing up on Tiktok this month. The content is focused on being vulnerable and sharing secrets/thoughts without judgement. This trend is most popular among couples, however, you can do this trend with anyone (your friends, team, family etc). You can hop on this trend by bringing a few people together to share your thoughts and you can only listen, without sharing any judgement. This content is currently high in engagement and viewership, and a great way to stay relevent on trends.",
  timeline: "December 2024",
  headerDesc: "This trend is blowing up on Tiktok this month...",
  primaryChannel: ["instagram","tiktok"]
},
{
  title: "Women in Male Fields",
  description: "This is a growing trend across Tiktok to show the power of women and how they are truly dominating in traditionally male dominated fields. Use this trend to share some appreciation for the amazing women in your life that are crushing it.",
  timeline: "December 2024",
  headerDesc: "This is a growing trend across Tiktok to show the power...",
  primaryChannel: ["instagram","tiktok"]
},
]

export const TOP_CREATORS = [{
  name:"kyliejenner",
  industry:"Beauty",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"selenagomez",
  industry:"Beauty",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"kimkardashian",
  industry:"Beauty",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"jamescharles",
  industry:"Beauty",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"huda",
  industry:"Beauty",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"jeffreestar",
  industry:"Beauty",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"nikkietutorials",
  industry:"Beauty",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"gordongram",
  industry:"Food & Bev",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"jamieoliver",
  industry:"Food & Bev",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"thepioneerwoman",
  industry:"Food & Bev",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"marthastewart",
  industry:"Food & Bev",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"turkuazkitchen",
  industry:"Food & Bev",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"halfbakedharvest",
  industry:"Food & Bev",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"plantyou",
  industry:"Food & Bev",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"thisisbillgates",
  industry:"Business & Technology",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"mkbhd",
  industry:"Business & Technology",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"unboxtherapy",
  industry:"Business & Technology",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"garyvee",
  industry:"Business & Technology",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"tonyrobbins",
  industry:"Business & Technology",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"richardbranson",
  industry:"Business & Technology",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"jeffbezos",
  industry:"Business & Technology",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"chiaraferragni",
  industry:"Fashion & Clothing",
  followers:"",
  posts:"",
  profileUrl:""
},
{
  name:"aimeesong",
  industry:"Fashion & Clothing",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"camilacoelho",
  industry:"Fashion & Clothing",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"avani",
  industry:"Fashion & Clothing",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"emmachamberlain",
  industry:"Fashion & Clothing",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"wolfiecindy",
  industry:"Fashion & Clothing",
  followers:"",
  posts:"",
  profileUrl:""
},{
  name:"mirandakerr",
  industry:"Fashion & Clothing",
  followers:"",
  posts:"",
  profileUrl:""
},

]

export const COUNTRIES = [
  "All",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "South Korea",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
