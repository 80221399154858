import { Box, Button, Chip, Stack, Typography, useTheme } from "@mui/material"
import { IconBell, IconBrandFacebook, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube, IconChartArrowsVertical, IconChartDots, IconMessage, IconMessages, IconPercentage, IconSpeakerphone, IconUsers } from "@tabler/icons-react"
import { fetchGet, transformFirebaseTime } from "../util/helpers"
import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import CreatorCarousel from "../components/CreatorCarousel";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
    const [campaigns, setCampaigns] = useState([]);
    const { user } = useContext(UserContext);
    const { setIsLoading, setLoadingText } = useContext(LoadingContext);
    const [creators, setCreators] = useState([]);
    const theme = useTheme();
    const navigate = useNavigate();
    
    const fetchCreators = () => {
        fetchGet(`/brand/outreach?campaignId=undefined`)
          .then((result) => result.result)
          .then((result) => {
            setCreators(result);
          });
      };
    const fetchCampaigns = async () => {
        const results = await fetchGet("/brand/campaigns", setIsLoading);
        setCampaigns(results.result
          .sort(
            (a, b) =>
              transformFirebaseTime(b.submittedAt) -
              transformFirebaseTime(a.submittedAt),
          ));
      };
    
    const platformToIcon = {
    "youtube": <IconBrandYoutube size="25" className="icon-colored" color="var(--main-text-color)"/>,
    "tiktok": <IconBrandTiktok size="25" className="icon-colored" color="var(--main-text-color)"/>,
    "facebook": <IconBrandFacebook size="25" className="icon-colored" color="var(--main-text-color)"/>,
    "instagram": <IconBrandInstagram size="25" className="icon-colored" color="var(--main-text-color)"/>
    }
    console.log(campaigns)

    useEffect(() => {
    if (user.id) {
        fetchCampaigns();
        fetchCreators();
    }
    }, [user]);

    return (
        <Box sx={{height: "100%", width: "100%", padding: "3%"}}>
            <Stack direction="column" spacing={4} width="100%">
                <Typography variant="h2">My Dashboard</Typography>
                <Stack direction="column" spacing={2} width="100%">
                    <Stack direction="row" spacing={2}>
                        <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                            <IconChartDots size="20"className="icon"/>
                        </Box>
                        <Typography variant="h3">Metrics</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} width="100%">
                        <Box className="box" width="25%">
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="bodyregm" color="var(--body-text-color-600)">Active Campaigns</Typography>
                                    <Box className="icon-box" sx={{backgroundColor: "var(--background-color-yellow)"}}>
                                        <IconSpeakerphone className="icon-colored" size="24" color="var(--icon-color-yellow)"/>
                                    </Box>
                                </Stack>
                                <Typography variant="h2">{campaigns?.filter(campaign => campaign.active)?.length}</Typography>
                            </Stack>
                        </Box>
                        <Box className="box" width="25%">
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="bodyregm" color="var(--body-text-color-600)">Total Views</Typography>
                                    <Box className="icon-box" sx={{backgroundColor: "var(--background-color-green)"}}>
                                        <IconChartArrowsVertical className="icon-colored" size="24"color="var(--icon-color-green)"/>
                                    </Box>
                                </Stack>
                                <Typography variant="h2">-</Typography>
                            </Stack>
                        </Box>
                        <Box className="box" width="25%">
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="bodyregm" color="var(--body-text-color-600)">Total Engagement</Typography>
                                    <Box className="icon-box" sx={{backgroundColor: "var(--background-color-purple)"}}>
                                        <IconMessages className="icon-colored" size="24" color="var(--icon-color-purple)"/>
                                    </Box>
                                </Stack>
                                <Typography variant="h2">-</Typography>
                            </Stack>
                        </Box>
                        <Box className="box" width="25%">
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="bodyregm" color="var(--body-text-color-600)">Total Engagement Rate</Typography>
                                <Box className="icon-box" sx={{backgroundColor: "var(--background-color-pink)"}}>
                                    <IconPercentage className="icon-colored" size="24"color="var(--icon-color-pink)"/>
                                    </Box>
                                </Stack>
                                <Typography variant="h2">-</Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
                <Stack direction="column" spacing={2} width="100%">
                    <Stack direction="row" spacing={2}>
                        <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                            <IconSpeakerphone size="20" className="icon"/>
                        </Box>
                        <Typography variant="h3">My Campaigns</Typography>
                    </Stack>
                    {campaigns && campaigns?.filter(campaign => campaign)?.map((campaign, index) => {
                        return (
                            <Box key={index} className="box" sx={{height: "130px", width: "100%"}}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                                        <Stack direction="row" spacing={4} width="90%">
                                            <Stack direction="column" spacing={1} sx={{width: "30%", height: "100%"}} justifyContent="space-between">
                                                <Typography variant="h4" height="57px">{campaign.name}</Typography>
                                                <Stack direction="row" spacing={1}>
                                                    <Chip label={campaign.active ? "Active" : "Inactive"} icon={<Box className={campaign.active ? "active-dot" : "inactive-dot"}/>} size="small"
                                                    sx={{backgroundColor: campaign.active ? theme.palette.success.light : theme.palette.error.light, color: campaign.active ? theme.palette.success.main : 'white'}}/>
                                                    <Chip label={campaign.influencers?.length} size="small"
                                                    icon={<IconUsers className="icon-colored" size="12" color={theme.palette.info.main}/>} 
                                                    sx={{backgroundColor: theme.palette.info.light, color: theme.palette.info.main}}/>
                                                    <Chip size="small"
                                                    icon={<IconMessage className="icon-colored" size="12" color={theme.palette.info.main}/>} 
                                                    sx={{backgroundColor: theme.palette.info.light, color: theme.palette.info.main, paddingLeft: "4px"}}/>
                                                    <Chip size="small"
                                                    icon={<IconBell className="icon-colored" size="12" color={theme.palette.info.main}/>} 
                                                    sx={{backgroundColor: theme.palette.info.light, color: theme.palette.info.main, paddingLeft: "4px"}}/>
                                                </Stack>
                                            </Stack>
                                            <Stack direction="column" spacing={1} justifyContent="center">
                                                <Typography variant="bodyxs" color="var(--body-text-color-600)">Views</Typography>
                                                <Typography variant="bodym">-</Typography>
                                            </Stack>
                                            <Stack direction="column" spacing={1} justifyContent="center">
                                                <Typography variant="bodyxs" color="var(--body-text-color-600)">Engagement</Typography>
                                                <Typography variant="bodym">-</Typography>
                                            </Stack>
                                            <Stack direction="column" spacing={1} justifyContent="center">
                                                <Typography variant="bodyxs" color="var(--body-text-color-600)">Engagement Rate</Typography>
                                                <Typography variant="bodym">-%</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center" sx={{width: "15%"}}>
                                                {campaign.platforms.map((platform) => platformToIcon[platform])}
                                            </Stack>
                                        </Stack>
                                        <Button onClick={() => navigate(`/campaigns/${campaign.id}`)} variant="outlined" className="outlined-button" color="info">View Details</Button>
                                    </Stack>
                            </Box>
                    )})}
                </Stack>
                <CreatorCarousel creators={creators} title="Top Trending Creators" />
                {/* //TODO: my lists */}
                {/* <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                            <IconList size="20" className="icon"/>
                        </Box>
                        <Typography variant="h3">My Lists</Typography>
                    </Stack>
                </Stack> */}
            </Stack>
        </Box>
    )
}