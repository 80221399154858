import {
    Typography,
    Paper,
    Grid
} from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import UserContext from "../contexts/UserContext";
import { Button, CreatorLeftSideBar } from "../components";
import {
    fetchGet, fetchPost
} from "../util/helpers";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../contexts/LoadingContext";
import toast, { Toaster } from "react-hot-toast";


const Creators = () => {
    const [rightGridHeight, setRightGridHeight] = useState(0);
    const rightGridRef = useRef(null);
    const [campaigns, setCampaigns] = useState([]);
    const [applications, setApplications] = useState([]);
    const [matches, setMatches] = useState([]);
    const { setIsLoading } = useContext(LoadingContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (rightGridRef.current) {
            const updateHeight = () => {
                setRightGridHeight(rightGridRef.current.clientHeight);
            };


            updateHeight();

            window.addEventListener('resize', updateHeight);

            return () => {
                window.removeEventListener('resize', updateHeight);
            };
        }
    }, [rightGridRef.current]);


    const fetchCampaigns = async () => {
        const results = await fetchGet("/creator/campaigns", setIsLoading);
        const allResults = await fetchGet("/creator/applications", setIsLoading);

        setCampaigns(results.result);
        setApplications(allResults.result.filter((data) => data.status === "interested"));
        setMatches(allResults.result.filter((data) => data.status === "matched"));

    };

    const handleChoice = async (data, choice) => {
        try {
            const response = await fetchPost(
                `/creator/responses`,
                { campaignId: data.id, status: choice },
                setIsLoading
            );
            if (response.result === "Success") {
                toast.success(
                    `Successfully response added`,
                );
                const newCampList = campaigns.filter((listData) => listData.id != data.id);
                const newResCampList = [...applications];
                newResCampList.push({ ...data, status: choice });
                setApplications([...newResCampList]);
                setCampaigns([...newCampList]);
            }
            else {
                toast.error("Something went wrong, please try again later");
            }
        } catch (e) {
            toast.error("Something went wrong, please try again later");
        }


    }

    useEffect(() => {

     if (user.id) {
        if(!user.isSetup)navigate("/creatorSetup");
        else fetchCampaigns();
        }
    }, [user]);

    return <>


        <Grid container justifyContent={"center"} alignItems={"center"}>
                   
                    <Grid item xs={8} style={{ textAlign: "left", marginTop: 50, marginBottom: 50, }} ref={rightGridRef}>

                    <div style={{
                        width: "100%",
                        height: "100%",
                        textAlign: "left",
                        marginTop: 5,
                        paddingTop: 10,
                        paddingBottom: 20,
                    }}>
                        <Toaster
                            position="top-center"
                            toastOptions={{ duration: 3000 }}
                            style={{ width: 5000 }}
                        />
                        <Typography
                            variant="h4"
                            component="div"
                            style={{ fontWeight: "bolder", lineHeight: "2" }}
                        >
                            Brand Campaign Dashboard
                        </Typography>
                        <Typography
                            variant="h7"
                            component="div"
                            style={{  lineHeight: "2", }}
                        >
                            Explore the latest campaign opportunities and apply to work with brands you love!
                        </Typography>
                    </div>
                     
                            {campaigns.length > 0 && <>
                                <Typography
                                    variant="h7"
                                    style={{
                                        fontWeight: 800,
                                        textAlign: "left",
                                        marginTop: 20,
                                        marginBottom: 30,
                                    }}
                                >
                                    Are you interested in these brand campaigns?
                                </Typography>
                                <br />
                                <br />
                                {campaigns.map((data) => {
                                    return <CampaignCard data={data} handleButtonClick={handleChoice} />
                                })}
                            </>}
                            {applications.length > 0 && <>
                                <Grid
                                    container
                                    sx={{
                                        margin: "0 auto",
                                        textAlign: "center",
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                        backgroundColor: "black",
                                        minHeight: 10,
                                        marginTop: 60,
                                        marginBottom: 50,
                                    }}
                                ></Grid>
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontWeight: 800,
                                        textAlign: "left",
                                        marginTop: 20,
                                        marginBottom: 10,
                                    }}
                                >
                                    Your Campaign Applications
                                </Typography>
                                {applications.map((data) => {
                                    return <CampaignCard data={data} />
                                })}
                            </>}
                            {matches.length > 0 && <>
                                <Grid
                                    container
                                    sx={{
                                        margin: "0 auto",
                                        textAlign: "center",
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                        backgroundColor: "black",
                                        minHeight: 10,
                                        marginTop: 50,
                                        marginBottom: 50,
                                    }}
                                ></Grid>
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontWeight: 800,
                                        textAlign: "left",
                                        marginTop: 20,
                                        marginBottom: 10,
                                    }}
                                >
                                    Your Matches
                                </Typography>
                                {matches.map((data) => {
                                    return <CampaignCard data={data} />
                                })}
                            </>}
                    </Grid>
        </Grid>


    </>

}

const CampaignCard = ({ data, handleButtonClick }) => {
    const [showMore, setShowMore] = useState(false);

    const navigate = useNavigate();

    const handleToggleShowMore = () => {
        setShowMore(!showMore);
    };
    const descriptionStyle = {
        maxHeight: showMore ? 'none' : '120px',
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out'
    };

    return <Paper
        elevation={2}
        style={{
            paddingTop: 5,
            paddingBottom: 5,
            marginBottom: 20,
            borderRadius: 10,
            transition: "transform 0.3s ease-in-out",
            ...(data.status === "interested" && {
                boxShadow: `1px 1px 14px 1px rgb(213, 126, 235)`,
            })
        }}
    >
        <div style={{ margin: 20, display: "flex", flexDirection: "row", }}>
            <div style={{ width: "25%" }}>
                <img style={{ height: "10rem", width: "10rem" }} src={data.image} />
                <br/>
                <Typography
                    variant="body"
                    style={{ textAlign: "left", wordWrap: "break-word", }}
                >
                    Industry:
                    <span style={{ backgroundColor: "#F9F2FD" }}> {data?.industry?.toString()}</span>
                </Typography>
            </div>
            <div style={{ width: "75%", paddingLeft: 15, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div>
                    <Typography
                        variant="h5"
                        component="div"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                        {data.name}
                    </Typography>
                    <div style={descriptionStyle}>
                        <Typography
                            variant="h7"
                            component="p"
                            style={{ textAlign: "left", paddingTop: 15, whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                        >
                            {data?.description?.replaceAll("\\n", "\n")}
                        </Typography>
                        <Typography
                            variant="h7"
                            component="div"
                            style={{ textAlign: "left", paddingTop: 15, whiteSpace: "pre-wrap", wordWrap: "break-word" }}

                        >
                            {data?.talking_points?.replaceAll("\\n", "\n")}
                        </Typography>
                        <Typography
                            variant="h7"
                            component="div"
                            style={{ textAlign: "left", paddingTop: 15 }}

                        >
                            <span style={{ fontWeight: "bold" }}>Location </span>{data?.region?.toString()}
                        </Typography>
                    </div>

                    <Typography
                        variant="h7"
                        component="div"
                        style={{ textAlign: "left", cursor: "pointer", fontWeight: "bold" }}
                        onClick={handleToggleShowMore}
                    >
                        {!showMore ? "See more..." : "See less..."}
                    </Typography>
                </div>
                <div style={{ paddingTop: 20 }}>
                    {
                        (!data.status) && <>
                            <Button
                                style={{ width: 250 }}
                                onClick={(e) => handleButtonClick(data, "uninterested")}>
                                Not Interested
                            </Button>
                            <Button sx={{
                                backgroundColor: "#4158D0",
                                backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                                marginLeft: 10,
                                width: 250
                            }}
                                onClick={(e) => handleButtonClick(data, "interested")}
                            >Interested</Button>
                        </>}
                    {
                        data.status === "matched" && <Button sx={{
                            backgroundColor: "#4158D0",
                            backgroundImage: "linear-gradient(90deg, #0062ff, #da61ff)"


                        }}
                            onClick={() => navigate(`${data.campaignId}/workstream`)}
                        >Visit Workstream</Button>
                    }
                </div>
            </div>
        </div>
    </Paper>
}

export default Creators;