import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/UserContext";
import {
  signInWithEmail,
  signInWithGoogle,
  signUpWithEmail,
} from "../util/helpers";
import {
  IconButton,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  TextareaAutosize
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { INDUSTRIES, COUNTRIES } from "../util/constants";
import creatorRegistrationDesign from "../assets/creatorRegistrationPage.png";
import creatorSignUp from "../assets/Product Design.png";
import { useMediaQuery } from "@mui/material";
import EllipseTop from "../assets/Ellipse-Top.png"
import EllipseBottom from "../assets/Ellipse-Bottom.png"

// import { Browser } from "@capacitor/browser";
// import UserContext from "../../contexts/User";
import {
  fetchPut,
  creatorSignUpWithEmail,
  getBase64
} from "../util/helpers.js";
import FileUpload from "../components/FileUpload";
import LoadingContext from "../contexts/LoadingContext";




export default function CreatorSignUp() {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoadingContext);
  const { authed, refreshUser, user } = useContext(UserContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [rate, setRate] = useState("");
  const [birthday, setBirthday] = useState("");
  const [newCreatorProfile, setNewCreatorProfile] = useState({});

  const [tiktokDisabled, setTiktokDisabled] = useState(false);
  const [youtubeDisabled, setYoutubeDisabled] = useState(false);
  const [facebookDisabled, setFacebookDisabled] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const [newCreatorImage, setNewCreatorImage] = useState();

  // const handleTiktokLogin = () => {
  //     setHasClicked(true);
  //     fetchGet(`/oauth/tiktok`).then((res) => {
  //       console.log(res);
  //       Browser.open({ url: res.url });
  //     });
  //   };

  //   const handleYoutubeLogin = () => {
  //     setHasClicked(true);
  //     fetchGet(`/oauth/youtube`).then((res) => {
  //       console.log(res);
  //       Browser.open({ url: res.url });
  //     });
  //   };

  //   const handleFacebookLogin = () => {
  //     setHasClicked(true);
  //     fetchGet(`/oauth/facebook`).then((res) => {
  //       console.log(res.url);
  //       window.open(res.url) // browser.open throws an invalid url error
  //       // Browser.open({ url: res.url });
  //     });
  //   };

  const handleChange = (name, value) => {
    setNewCreatorProfile((prev) => ({
      ...prev,
      [name]: value
    }));
  }

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (newCreatorProfile.imageBlobs) {
      try{
        const result = await creatorSignUpWithEmail(newCreatorProfile.email, newCreatorProfile.password);
      const baseImageList = [];
      for (let i = 0; i < newCreatorProfile.imageBlobs.length; i++) {
        const data = await getBase64(newCreatorProfile.imageBlobs[i]);
        baseImageList.push({ blob: data, format: newCreatorProfile.imageBlobs[i].name.split('.').pop() })
      }
      await fetchPut(`/creator/users`, { ...newCreatorProfile, imageBlobs: baseImageList }, setIsLoading);
      await refreshUser(result, true);
      }catch(e){
        toast.error(e.errors[0]["message"]);
      }
    }
    else {
      toast.error("Please, Select your profile picture");

    }
  }

  useEffect(() => {
    if (authed) {
      navigate(`/creators`);
    }
  }, [authed]);
  return <>
    <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

    <div style={{ display: "flex"}}>

    <div
      style={{ display: "flex", justifyContent: `${isMobile?"center":"left"}`, width: "60%" }}
    >
      <form onSubmit={handleRegistration} style={{ display: "flex", marginLeft: 100, flexDirection: "column", width: `${isMobile?"80%":"65%"}`, gap: 15, marginTop: 100 }}>


        <Typography variant="h3" >Get Started as a Creator</Typography>
        <Typography variant="body">Join the Gigl creator community and access new brand opportunties.</Typography>

        <Typography sx={{ textAlign: "left", fontWeight: 800, }}>Full Name</Typography>
        <TextField
          type="name"
          name="name"
          style={{ width: "100%" }}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          value={newCreatorProfile.name}
          placeholder="Enter full name"
          required
        />

        <Typography sx={{ textAlign: "left", fontWeight: 800, }}>Email</Typography>
        <TextField
          type="email"
          name="email"
          style={{ width: "100%" }}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          value={newCreatorProfile.email}
          placeholder="Enter email"
          required
        />
        <Typography sx={{ textAlign: "left", fontWeight: 800, }}>Password</Typography>
        <TextField
          type="password"
          name="password"
          style={{ width: "100%" }}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          value={newCreatorProfile.password}
          placeholder="Enter strong password"
          required
        />

        <Typography sx={{ textAlign: "left", fontWeight: 800, }}>About</Typography>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            '&:hover': {
              border: "1px solid black"
            },
            '&:focus-within': {
              border: "2px solid #1976d2"
            },
            border: '1px solid gray',
            borderRadius: '4px',
            padding: '4px',
          }}
        >
          <TextareaAutosize
            name="description"
            required
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            fullWidth
            style={{ width: "100%", padding: 10, border: 'none', outline: 'none', boxSizing: 'border-box', }}
            minRows={5}
            value={newCreatorProfile.description}
            placeholder="Share 1-2 sentences about yourself and your content style/brand"
          />
        </Box>
        {/* <Typography
                    variant="subtitle1"
                    style={{
                        textAlign: "left", fontWeight: 800
                    }}
                >
                    Region
                </Typography>
                <FormControl required style={{ width: "100%" }}>
                    <Select
                        value={newCreatorProfile.region || []}
                        name="region"
                        required
                        onChange={(e) => handleChange(e.target.name, typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)}
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em style={{ color: "grey" }}>Select regions</em>;
                            }
                            return selected.join(', ');
                        }}
                    >
                        {COUNTRIES.map((country) => ({
                            label: country,
                            value: country,
                        })).map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography sx={{ textAlign: "left", fontWeight: 800 }}>
                    What City Are You Based In?
                </Typography>
                <TextField
                    type="city"
                    name="city"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={newCreatorProfile.city}
                    placeholder="Enter city"
                />

                <Typography sx={{ textAlign: "left", fontWeight: 800 }}>
                    Date of Birth (mm/dd/yyyy){" "}
                </Typography>
                <TextField
                    type="birthday"
                    name="birthday"
                    fullWidth
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={newCreatorProfile.birthday}
                    placeholder="Enter DOB in mm/dd/yyyy formate"
                /> */}


        <Typography
          variant="subtitle1"
          style={{
            textAlign: "left", fontWeight: 800
          }}
        >
          What is the focus of your content?
        </Typography>
        <FormControl required style={{ width: "100%", }}>
          <Select
            value={newCreatorProfile.industries || []}
            name="industries"
            required
            onChange={(e) => handleChange(e.target.name, typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)}
            multiple
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em style={{ color: "grey" }}>Select industries</em>;
              }
              return selected.join(', ');
            }}
          >
            {INDUSTRIES.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <Typography
                    variant="subtitle1"
                    style={{
                        textAlign: "left", fontWeight: 800
                    }}
                >
                    Gender
                </Typography>

                <FormControl required style={{ width: "100%" }}>
                    <Select
                        value={newCreatorProfile.gender || []}
                        name="gender"
                        required
                        onChange={(e) => handleChange(e.target.name, typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em style={{ color: "grey" }}>Select gender</em>;
                            }
                            return selected.join(', ');
                        }}
                    >
                        {[
                            { label: "Female", value: "female" },
                            { label: "Male", value: "male" },
                            { label: "Non-Binary", value: "non-binary" },
                        ].map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

        <Typography
          variant="subtitle1"
          style={{
            textAlign: "left", fontWeight: 800
          }}
        >
          Upload 2-3 Profile Pictures that brands can see
        </Typography>
        <FileUpload
          name="imageBlobs"
          containerProps={{
            style: { width: "100%", marginBottom: 20, textAlign: "center" },
          }}
          handleFilesChange={async (files) => {
            if (files !== null && files?.length > 0) {
              // setNewCreatorImage(files[0]);
              handleChange("imageBlobs", [...files]);
            }
          }}
          required
        />
        <Button
          gradient
          appearance="default"
          marginTop={10}
          type="submit"
          style={{ width: "100%", borderRadius: 10 }}
          onClick={() => handleRegistration()}
        >
          Get Started!
        </Button>
        <hr
          style={{
            width: "50%",
            height: 1,
            border: 0,
            borderTop: "1px solid #ccc",
            padding: 0,
          }}
        />

        <Typography
          variant="body"
          sx={{ textAlign: "center", marginBottom: 20, fontWeight: 800 }}
        >
          Already have an account?{" "}
          <span
            style={{ cursor: "pointer", color: "#004bb9" }}
            onClick={() => navigate(`/creatorlogin`)}
          >
            {" "}
            Sign in now
          </span>
        </Typography>

      </form>
    </div>

    <div
      style={{ display: "flex", justifyContent: `${isMobile?"center":"right"}`, width: "40%", backgroundColor: "#7b68ee" }}
    >
      <img src={creatorSignUp} style={{justifyContent: "right", width: "80%", height: "90%", marginRight: 60, paddingTop: 2}}>
      </img>

      
    </div>

    </div>
  </>
}
